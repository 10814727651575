body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 100%;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 100%;
  }
}

.css-1ddhgr0.Target-root{
  font-size: 8px!important;
}